/** @jsx jsx */
import { useContext } from 'react'
import { jsx } from 'theme-ui'
import { useSelector } from 'react-redux'
import { Col, Row } from '@uswitch/trustyle.flex-grid'
import { ContentfulModule } from '@h/modules'
import ModuleWrapper, { ModuleWrapperContext } from '@h/module-wrapper'

const arrayToPairs = array =>
  array.reduce((acc, val, i) => (i % 2 === 0 ? [...acc, [val, array[i + 1]]] : acc), [])

export default ({ entries, options = {} }) => {
  const pairedEntries = arrayToPairs(entries)
  const { span = 12, offset = 0 } = useSelector(
    state => state.app.meta?.collectionTwoColContentConfig || {},
  )

  const wrapperContext = useContext(ModuleWrapperContext)
  const defaultSpacing = wrapperContext.alreadyWrapped ? 0 : 'lg'
  const { spacingTop = defaultSpacing, spacingBottom = defaultSpacing } = options

  const rowSx = { variant: 'modules.collection-two-col-content' }

  return (
    <ModuleWrapper spacingTop={spacingTop} spacingBottom={spacingBottom}>
      <Row>
        <Col span={span} offset={offset}>
          <For index='key' each='pair' of={pairedEntries}>
            <Row sx={rowSx}>
              <For index='key' each='entry' of={pair}>
                <Col span={[12, 6]} key={key}>
                  <ContentfulModule props={{ entity: entry }} />
                </Col>
              </For>
            </Row>
          </For>
        </Col>
      </Row>
    </ModuleWrapper>
  )
}
